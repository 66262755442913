let baseUrl = 'http://localhost:5000/api/v1';
let apiTokenExpirationInMinutes = 60;

if (process.env.REACT_APP_BASE_API_URL) {
  baseUrl = process.env.REACT_APP_BASE_API_URL;
}

if (process.env.REACT_APP_API_TOKEN_EXPIRATION_IN_MINUTES) {
  apiTokenExpirationInMinutes = Number.parseInt(process.env.REACT_APP_API_TOKEN_EXPIRATION_IN_MINUTES, 10);
}

export default { baseUrl, apiTokenExpirationInMinutes };
