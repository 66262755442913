import { IEnvironmentRegex } from '../types';

const phoneNumberRegex = /^(\+420)? ?[1-9]\d{2}(?: ?\d{3}){2}$/i; // ^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$ // /^[1-9]\d{8}$/i;
/* eslint-disable-next-line unicorn/better-regex */
const phoneNumberWithPrefixRegex = /^(\+\d{3})? ?[1-9]\d{2}(?: ?\d{3}){2}$/i;
const phoneNumberOnlyWithPrefixRegex = /^(\+420) ?[1-9]\d{2}(?: ?\d{3}){2}$/i;

export const CzEnvironmentRegex: IEnvironmentRegex = {
  phoneNumberRegex,
  phoneNumberWithPrefixRegex,
  phoneNumberOnlyWithPrefixRegex,
};
