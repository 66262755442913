import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Backdrop, CircularProgress, makeStyles, createStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules<FullPageLoadingIndicatorClassKey, FullPageLoadingIndicatorStyleProps> =>
    createStyles({
      root: {
        position: 'fixed',
        zIndex: 2300,
        right: '0px',
        bottom: '0px',
        top: '0px',
        left: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: (props) => props.visibility,
      },
    })
);

type FullPageLoadingIndicatorClassKey = 'root';

type FullPageLoadingIndicatorStyleProps = {
  visibility: 'visible' | 'hidden';
};

interface IFullPageLoadingIndicatorProps extends IFullPageLoadingIndicatorStateProps {
  size: number;
}

interface IFullPageLoadingIndicatorStateProps {
  show: boolean;
}

const FullPageLoadingIndicatorContainer: React.FunctionComponent<IFullPageLoadingIndicatorProps> = memo(
  (props): React.ReactElement => {
    console.debug('FullPageLoadingIndicator');
    const classes = useStyles({ visibility: props.show ? 'visible' : 'hidden' });

    return (
      <div className={classes.root}>
        <Backdrop open={props.show} />
        <CircularProgress size={props.size} />
      </div>
    );
  }
);

const mapStateToProps = ({ loadingIndicator }: any): IFullPageLoadingIndicatorStateProps => {
  return {
    show: loadingIndicator.showIndicator,
  };
};

export default connect(mapStateToProps)(FullPageLoadingIndicatorContainer);
