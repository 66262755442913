import { AxiosInstance } from 'axios';

import { BaseResponse, BaseDataResponse } from '../responses';
import BaseJsonClient from './baseJsonClient';
import { IErrorHandler } from '../abstractions';
import { IBaseModel } from '../interfaces';

export interface ApiModelClient<TModel extends IBaseModel> {
  getAllAsync(): Promise<BaseDataResponse<TModel[]>>;
  updateAsync(model: TModel): Promise<BaseDataResponse<TModel>>;
  createAsync(model: TModel): Promise<BaseDataResponse<TModel>>;
  deleteAsync(id: number): Promise<BaseResponse>;
}

export interface ApiModelClientWithDelete {
  deleteAsync(id: number): Promise<BaseResponse>;
}

export default class BaseClient extends BaseJsonClient {
  protected readonly axiosInstance: AxiosInstance;

  protected readonly errorHandler: IErrorHandler;

  protected readonly modelPath: string;

  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance, modelPath: string) {
    super(axiosInstance, modelPath);

    this.errorHandler = errorHandler;
    this.axiosInstance = axiosInstance;
    this.modelPath = modelPath;
  }

  protected async getHttpAsync<TResponse extends BaseResponse>(route: string, query: string): Promise<TResponse> {
    try {
      const response = await this.errorHandler.handleRequestAsync(async () => {
        return super.getHttpAsync<TResponse>(route, query);
      });

      return response;
    } catch (error) {
      const errorResposne = new BaseResponse();
      errorResposne.resultCode = -1;
      errorResposne.resultReason = error.message;

      return errorResposne as TResponse;
    }
  }

  protected async putHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
    try {
      const response = await this.errorHandler.handleRequestAsync(async () => {
        return super.putHttpAsync<TResponse, TData>(route, data);
      });

      return response;
    } catch (error) {
      const errorResposne = new BaseResponse();
      errorResposne.resultCode = -1;
      errorResposne.resultReason = error.message;

      return errorResposne as TResponse;
    }
  }

  protected async postHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
    try {
      const response = await this.errorHandler.handleRequestAsync(async () => {
        return super.postHttpAsync<TResponse, TData>(route, data);
      });

      return response;
    } catch (error) {
      const errorResposne = new BaseResponse();
      errorResposne.resultCode = -1;
      errorResposne.resultReason = error.message;

      return errorResposne as TResponse;
    }
  }

  protected async postFormDataHttpAsync<TResponse extends BaseResponse>(route: string, data: FormData): Promise<TResponse> {
    try {
      const response = await this.errorHandler.handleRequestAsync(async () => {
        return super.postFormDataHttpAsync<TResponse>(route, data);
      });

      return response;
    } catch (error) {
      const errorResposne = new BaseResponse();
      errorResposne.resultCode = -1;
      errorResposne.resultReason = error.message;

      return errorResposne as TResponse;
    }
  }

  protected async deleteHttpAsync<TResponse extends BaseResponse>(route: string): Promise<TResponse> {
    try {
      const response = await this.errorHandler.handleRequestAsync(async () => {
        return super.deleteHttpAsync<TResponse>(route);
      });

      return response;
    } catch (error) {
      const errorResposne = new BaseResponse();
      errorResposne.resultCode = -1;
      errorResposne.resultReason = error.message;

      return errorResposne as TResponse;
    }
  }
}
