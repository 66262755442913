import React, { PropsWithChildren } from 'react';

import { IExternalLinkProps } from './types';
import ROUTES from './routes';

const ExternalLinkComponent = (props: PropsWithChildren<IExternalLinkProps>): React.ReactElement<PropsWithChildren<IExternalLinkProps>> => {
  const { children, className } = props;

  return (
    <a href={ROUTES[props.route]} target="_blank" rel="noopener noreferrer" className={className}>
      {children}
    </a>
  );
};

export default ExternalLinkComponent;
