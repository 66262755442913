import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction, PayloadActionCreator } from '@reduxjs/toolkit';

export const createAsyncAction = (
  actionTypePrfix: string
): {
  request: ActionCreatorWithoutPayload;
  success: ActionCreatorWithoutPayload;
  failure: ActionCreatorWithPayload<string>;
} => {
  return {
    request: createAction(`${actionTypePrfix}/request`),
    success: createAction(`${actionTypePrfix}/success`),
    failure: createAction<string>(`${actionTypePrfix}/failure`),
  };
};

export const createAsyncPaylodAction = <TRequest, TSuccess, TFailure>(
  actionTypePrfix: string
): {
  request: PayloadActionCreator<TRequest>;
  success: PayloadActionCreator<TSuccess>;
  failure: PayloadActionCreator<TFailure>;
} => {
  return {
    request: createAction<TRequest>(`${actionTypePrfix}/request`),
    success: createAction<TSuccess>(`${actionTypePrfix}/success`),
    failure: createAction<TFailure>(`${actionTypePrfix}/failure`),
  };
};
