import axios, { AxiosInstance } from 'axios';

import {
  AnnouncementClient,
  AuthenticationClient,
  CatalogClient,
  ClaimClient,
  ClientClient,
  ContractClient,
  XcallyClient,
  PickupTimeClient,
  WhistleblowingClient,
} from './clients';
import { AuthenticatedRequestHandler, ErrorHandler } from './handlers';
import { apiCallHandler } from './sagas';
import { StringToDateInterceptor } from './interceptors';

export class ApiClient {
  private axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 500000,
      withCredentials: true,
      headers: {},
      validateStatus: (status: number) => status >= 200 && status < 300,
    });

    this.registerInterceptors();

    const authenticatedRequestHandler = new AuthenticatedRequestHandler();
    const errorHandler = new ErrorHandler();

    this.Authentication = new AuthenticationClient(errorHandler, this.axiosInstance);
    this.Announcement = new AnnouncementClient(errorHandler, this.axiosInstance);
    this.Xcally = new XcallyClient(errorHandler, this.axiosInstance);
    this.Client = new ClientClient(errorHandler, this.axiosInstance);
    this.Catalog = new CatalogClient(errorHandler, this.axiosInstance);
    this.PickupTime = new PickupTimeClient(errorHandler, this.axiosInstance);
    this.Contract = new ContractClient(errorHandler, this.axiosInstance);
    this.Claim = new ClaimClient(errorHandler, this.axiosInstance);
    this.Whistleblowing = new WhistleblowingClient(errorHandler, this.axiosInstance);

    this.apiCallHandler = apiCallHandler;
  }

  public Authentication: AuthenticationClient;

  public Announcement: AnnouncementClient;

  public Xcally: XcallyClient;

  public Client: ClientClient;

  public Catalog: CatalogClient;

  public PickupTime: PickupTimeClient;

  public Contract: ContractClient;

  public Claim: ClaimClient;

  public Whistleblowing: WhistleblowingClient;

  public apiCallHandler: typeof apiCallHandler;

  private registerInterceptors = (): void => {
    /*  this.axiosInstance.interceptors.request.use(tenantInterceptor, error => {
            return Promise.reject(error);
        }); */

    this.axiosInstance.interceptors.response.use(
      (response) => StringToDateInterceptor.execute(response),
      (error) => {
        return Promise.reject(error);
      }
    );
  };
}
