import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';
import { SendOptRequestModel, SignOutRequestModel, VerifyOptRequestModel } from '../interfaces';

export default class AuthenticationClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'authentication');
  }

  public async sendOtpAsync(otpModel: SendOptRequestModel): Promise<BaseResponse> {
    const response = await this.postHttpAsync<BaseResponse, SendOptRequestModel>('send-otp', otpModel);

    return response;
  }

  public async verifyOtpAsync(otpModel: VerifyOptRequestModel): Promise<BaseResponse> {
    const response = await this.postHttpAsync<BaseResponse, VerifyOptRequestModel>('verify-otp', otpModel);

    return response;
  }

  public async loginAsync(userName: string, password: string): Promise<BaseResponse> {
    const data = { userName, password };

    return this.postHttpAsync<BaseResponse, typeof data>('grant-password', data);
  }

  public async signOut(): Promise<BaseResponse> {
    const data: SignOutRequestModel = { fakeSessionId: '' };

    return this.postHttpAsync<BaseResponse, SignOutRequestModel>('sign-out', data);
  }
}
