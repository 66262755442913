import { createReducer } from '@reduxjs/toolkit';
import produce from 'immer';

import { sendCallBackFormActionAsync, openCallBackFormAction, loadCallCenterDateActionAsync } from './actions';
import { IXcallyState } from './types.state';

const initialState: IXcallyState = {
  errorMessage: undefined,
  dates: [],
  initialFocusedDate: undefined,
  pickupDates: [],
  pickupTimes: {},
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(openCallBackFormAction, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = undefined;
      });

      return nextState;
    })
    .addCase(sendCallBackFormActionAsync.request, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = undefined;
      });

      return nextState;
    })
    .addCase(sendCallBackFormActionAsync.success, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = undefined;
      });

      return nextState;
    })
    .addCase(sendCallBackFormActionAsync.failure, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = action.payload;
      });

      return nextState;
    })

    .addCase(loadCallCenterDateActionAsync.request, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = undefined;
      });

      return nextState;
    })
    .addCase(loadCallCenterDateActionAsync.success, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = undefined;
        draftState.dates = action.payload;

        if (action.payload && action.payload.length > 0) {
          draftState.initialFocusedDate = action.payload[0].date;
          draftState.pickupDates = action.payload.map((callCenterDate) => callCenterDate.date);

          action.payload.forEach((callCenterDate) => {
            draftState.pickupTimes[callCenterDate.date.toUTCString()] = {
              date: callCenterDate.date.toUTCString(),
              times: callCenterDate.times.map((timeSlot) => {
                return {
                  date: callCenterDate.date.toUTCString(),
                  slotText: timeSlot.slotText,
                  id: timeSlot.startTime.toUTCString(),
                };
              }),
            };
          });
        }
      });

      return nextState;
    })
    .addCase(loadCallCenterDateActionAsync.failure, (state, action) => {
      const nextState: IXcallyState = produce(state, (draftState) => {
        draftState.errorMessage = action.payload;
      });

      return nextState;
    })
);

export default reducer;
