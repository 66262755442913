import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';

import 'core-js';
import 'core-js/es';
/* import 'core-js/es/regexp';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/reflect'; */
import 'core-js/web';
import { enableES5 } from 'immer';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';

import { I18nLoader } from './features/translation';
import reportWebVitals from './reportWebVitals';
import { history } from './store/store.default';
import BrowserBaseApp from './features/browserBaseApp';
import { registerFormComponent } from './components/dialogManager/formComponentRegistrator';
import { XCallyCallBackFormContainer, XCallyCallBackFormComponentName } from './features/xcally';
import { FullPageLoadingIndicatorContainer } from './components/fullPageLoadingIndicator';
import { StoreLoader } from './store/loader';
import { HelmetComponentByEnviroment } from './helmet';

enableES5();
registerFormComponent(XCallyCallBackFormComponentName, XCallyCallBackFormContainer);

const renderLoader = (): React.ReactElement => <FullPageLoadingIndicatorContainer size={90} />;

ReactDOM.render(
  <React.StrictMode>
    {/* <Helmet htmlAttributes={{ lang: currentLanguageCode() }} /> */}
    <HelmetComponentByEnviroment />
    <StoreLoader>
      <Suspense fallback={renderLoader()}>
        <I18nLoader>
          <ConnectedRouter history={history}>
            <BrowserBaseApp />
          </ConnectedRouter>
        </I18nLoader>
      </Suspense>
    </StoreLoader>
  </React.StrictMode>,
  document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
