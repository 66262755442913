import React from 'react';
import { currentEnvironment } from '../../environment/environment';

const PrimatyButtonLazyLoader = (): React.ComponentType<any> => {
  switch (currentEnvironment()) {
    case 'cz':
      return React.lazy(() => import('./cz/primaryButton'));
    case 'hu':
      return React.lazy(() => import('./hu/primaryButton'));
    case 'sk':
      return React.lazy(() => import('./sk/primaryButton'));
    default:
      throw new Error('PrimatyButtonLazyLoader invalid enviroment');
  }
};

const PrimatyButtonLazy = PrimatyButtonLazyLoader();

export default PrimatyButtonLazy;
