import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseDataResponse } from '../responses';
import { IErrorHandler } from '../abstractions';
import { ComponentModel, DocumentTypeModel, SalesPointModel } from '../interfaces';

export default class CatalogClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'catalog');
  }

  public async getComponentsAsync(): Promise<BaseDataResponse<ComponentModel[]>> {
    return this.getHttpAsync<BaseDataResponse<ComponentModel[]>>('component', '');
  }

  public async getDocumentTypesAsync(): Promise<BaseDataResponse<DocumentTypeModel[]>> {
    return this.getHttpAsync<BaseDataResponse<DocumentTypeModel[]>>('document-type', '');
  }

  public async getSalesPointsAsync(): Promise<BaseDataResponse<SalesPointModel[]>> {
    return this.getHttpAsync<BaseDataResponse<SalesPointModel[]>>('salespoint', '');
  }
}
