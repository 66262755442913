import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { currentEnvironment } from '../../environment/environment';

export const themeLoaderAsync = (): Promise<Theme> => {
  let suspender: Promise<Theme>;

  switch (currentEnvironment()) {
    case 'cz':
      suspender = new Promise((resolve, reject) => {
        import('./cz/theme')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Theme cs cannot loaded!');
            reject(error);
          });
      });
      break;
    case 'hu':
      suspender = new Promise((resolve, reject) => {
        import('./hu/theme')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Theme hu cannot loaded!');
            reject(error);
          });
      });
      break;
    case 'sk':
      suspender = new Promise((resolve, reject) => {
        import('./sk/theme')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Theme sk cannot loaded!');
            reject(error);
          });
      });
      break;
    default:
      throw new Error(`Theme for environment ${currentEnvironment()} is not supported!`);
  }

  return suspender;
};
