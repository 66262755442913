/* let environment = 'cz';
let country = 'Česká republika'; */

let environment = 'hu';
let country = 'Maďarsko';

/* let environment = 'sk';
let country = 'Slovenská republika'; */

if (process.env.REACT_APP_ENVIROMENT) {
  environment = process.env.REACT_APP_ENVIROMENT;
}

if (process.env.REACT_APP_COUNTRY) {
  country = process.env.REACT_APP_COUNTRY;
}

export default { environment, country };
