import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

export type BaseDialogClassKey = 'paper' | 'dialogRoot' | 'dialogContentRoot';

export type BaseYesNoDialogClassKey = BaseDialogClassKey;

export type BaseFormDialogClassKey = BaseDialogClassKey | 'dialogTitle' | 'dialogDesciption';

export const baseYesNoDialogStyles = (theme: Theme): StyleRules<BaseYesNoDialogClassKey> =>
  createStyles({
    paper: {
      margin: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(6),
      },
    },
    dialogRoot: { padding: theme.spacing(5), flexGrow: 1 },
    dialogContentRoot: { padding: theme.spacing(5), textAlign: 'center' },
  });

export const baseFormDialogStyles = (theme: Theme): StyleRules<BaseFormDialogClassKey> =>
  createStyles({
    paper: {
      margin: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(6),
      },
    },
    dialogRoot: {
      paddingLeft: theme.spacing(2.25),
      paddingRight: theme.spacing(2.25),
      paddingTop: theme.spacing(2.75),
      paddingBottom: theme.spacing(2),
      flexGrow: 1,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(5.25),
        paddingRight: theme.spacing(5.25),
        paddingTop: theme.spacing(2.75),
        paddingBottom: theme.spacing(4),
      },
    },
    dialogContentRoot: { padding: theme.spacing(5), textAlign: 'center' },
    dialogTitle: { paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
    dialogDesciption: { paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
  });
