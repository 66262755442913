import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';
import { WhistleblowingPublicModel } from '../interfaces';

export default class WhistleblowingClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'whistleblowing');
  }

  public async postMessageAsync(mutation: WhistleblowingPublicModel): Promise<BaseResponse> {
    return this.postHttpAsync<BaseResponse, WhistleblowingPublicModel>('', mutation);
  }
}
