import React from 'react';
import { currentEnvironment } from '../../environment/environment';

const SecondaryButtonLazyLoader = (): React.ComponentType<any> => {
  switch (currentEnvironment()) {
    case 'cz':
      return React.lazy(() => import('./cz/secondaryButton'));
    case 'hu':
      return React.lazy(() => import('./hu/secondaryButton'));
    case 'sk':
      return React.lazy(() => import('./sk/secondaryButton'));
    default:
      throw new Error('SecondaryButtonLazyLoader invalid enviroment');
  }
};

const SecondaryButtonLazy = SecondaryButtonLazyLoader();

export default SecondaryButtonLazy;
