import React, { ReactElement, ReactNode, useEffect } from 'react';
import { dateLocaleLoaderAsync } from './environment';

const DateLocaleContext = React.createContext({} as Locale);

export const DateLocaleContextConsumer = DateLocaleContext.Consumer;

export const DateLocaleContextProvider = (props: { children: ReactNode }): ReactElement => {
  const [locale, setLocale] = React.useState<Locale>();

  useEffect(() => {
    dateLocaleLoaderAsync()
      .then(function (loadedLocale) {
        setLocale(loadedLocale);
        console.debug('Locale loaded:', loadedLocale);

        return loadedLocale;
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  if (!locale) {
    return <div />;
  }

  console.debug('DateLocaleContextProvider render');
  return <DateLocaleContext.Provider value={locale}>{props.children}</DateLocaleContext.Provider>;
};
