import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

export default class ContractClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'contract');
  }

  public async postMessageAsync(mutation: FormData): Promise<BaseResponse> {
    return this.postFormDataHttpAsync<BaseResponse>('question', mutation);
  }
}
