import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inputLabelRoot: {
      fontSize: '16px',
      '&$inputLabelOutlined': {
        transform: 'translate(16px, 17.5px) scale(1)',
        [theme.breakpoints.up('sm')]: {
          transform: 'translate(16px, 15px) scale(1)',
        },
      },
      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(16px, -6.5px) scale(0.75)',
      },
    },
    inputLabelOutlined: {},
    input: {
      backgroundColor: theme.palette.background.paper,
      height: '16px',
      lineHeight: 1,
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2.5),
      paddingTop: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(1.875),
        paddingTop: theme.spacing(1.875),
      },
    },
    autocompleteInputRoot: {
      '&&[class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
      '&&[class*="MuiOutlinedInput-root"] $autocompleteInput': {
        height: '16px',
        lineHeight: 1,
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2.5),
        paddingTop: theme.spacing(2.5),
        [theme.breakpoints.up('sm')]: {
          paddingBottom: theme.spacing(1.875),
          paddingTop: theme.spacing(1.875),
        },
      },
      '&&[class*="MuiOutlinedInput-root"] $autocompleteInput:first-child': {
        paddingLeft: theme.spacing(2),
      },
    },
    autocompleteInput: {},
    title: { fontSize: '16px', lineHeight: '24px', letterSpacing: '0.15px' },
    description: { fontSize: '12px', lineHeight: '16px', letterSpacing: '0.4px', color: theme.palette.grey[500], marginBottom: theme.spacing(0.75) },
  })
);
