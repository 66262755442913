import { BaseResponse } from '../responses';
import { IAuthenticatedRequestHandler } from '../abstractions';

export default class AuthenticatedRequestHandler implements IAuthenticatedRequestHandler {
  public handleRequestAsync = async <TResponse extends BaseResponse>(request: () => Promise<TResponse>): Promise<TResponse> => {
    // TODO: Udelat kontrolu zda jsou cookies a zda jsou platna, pokud ne asi redirect a integrace sagy

    /*  if (string.IsNullOrEmpty(credentialsStorage.BearerToken))
            {
                var response = await authenticationClient.Authenticate(
                                   credentialsStorage.ClientId,
                                   credentialsStorage.ClientSecret);

                if (response.ResultCode != 0)
                {
                    return response.ToBaseResponse() as TResponse;
                }

                // TODO - event mechanizmus by byl lepsi
                credentialsStorage.BearerToken = response.Data;
            } */

    return request();
  };
}
