import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedTextAreFieldProps } from './types';
import TextField from './textField';

function TextArea(props: ExtendedTextAreFieldProps): React.ReactElement {
  const [descriptionLength, setDescriptionLength] = useState(0);
  const { t } = useTranslation();
  const { defaultLabelKey, currentStateLabelKey, ...rest } = props;

  const onDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    if (event.target && event.target.value) {
      if (event.target.value.length <= props.maxLength) {
        props.form.setFieldValue(props.field.name, event.target.value);
        setDescriptionLength(event.target.value.length);
      }
    } else {
      props.form.setFieldValue(props.field.name, '');
      setDescriptionLength(0);
    }
  }, []);

  let descriptionLabel = t(defaultLabelKey, { minLength: props.minLength, currentLength: 0 });

  if (props.minLength && props.minLength > 0) {
    if (descriptionLength > 0 && descriptionLength < props.minLength) {
      descriptionLabel = t(currentStateLabelKey, { minLength: props.minLength, currentLength: descriptionLength });
    }
  } else if (props.maxLength && props.maxLength > 0) {
    if (descriptionLength > 0 && descriptionLength < props.maxLength) {
      descriptionLabel = t(currentStateLabelKey, { minLength: props.minLength, currentLength: descriptionLength });
    }
  }

  return (
    <React.Fragment>
      <TextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
        label={descriptionLabel}
        multiline
        rowsMax={4}
        onChange={onDescriptionChange}
      />
    </React.Fragment>
  );
}

export default TextArea;
