import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseDataResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

export default class AnnouncementClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'announcement');
  }

  public async postAnnouncementAsync(mutation: FormData): Promise<BaseDataResponse<string>> {
    return this.postFormDataHttpAsync<BaseDataResponse<string>>('', mutation);
  }
}
