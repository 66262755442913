import { TFunction } from 'i18next';
import React, { ReactElement, useEffect } from 'react';

import { i18nService } from './service';
import { FullPageLoadingIndicatorContainer } from '../../components/fullPageLoadingIndicator';

export const I18nLoader = (props: { children: ReactElement }): ReactElement => {
  const [isLoaded, setIsLoaded] = React.useState<boolean>();

  const i18nCallback = (error: any, t: TFunction): void => {
    /* setIsLoaded(!error); */
    setIsLoaded(true);
  };

  useEffect(() => {
    i18nService(i18nCallback);
  }, []);

  if (!isLoaded) {
    return <FullPageLoadingIndicatorContainer size={90} />;
  }

  console.debug('I18nLoader render');

  return props.children;
};
