import * as React from 'react';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { DialogType, YesNoModalDialogData } from './types';
import { BaseYesNoDialogClassKey, baseYesNoDialogStyles } from './baseDialog';
import { PrimaryButton, SecondaryButton } from '../button';

interface IYesNoDialogProps {
  isVisible: boolean;
  data: YesNoModalDialogData;
  classes: Partial<ClassNameMap<BaseYesNoDialogClassKey>>;
  dialogType: DialogType;

  onYesHandle(): void;
  onNoHandle(): void;
}

class YesNoDialogComponent extends React.PureComponent<IYesNoDialogProps> {
  render(): React.ReactNode {
    const dialogContetnTextComponent = this.props.data.description ? (
      <DialogContentText id="alert-dialog-description">{this.props.data.description}</DialogContentText>
    ) : (
      this.props.data.descriptionComponent && <this.props.data.descriptionComponent />
    );

    return (
      <Dialog
        open={this.props.isVisible}
        onClose={this.props.onNoHandle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: this.props.classes.paper }}
      >
        <Grid container justify="center">
          <div className={this.props.classes.dialogRoot}>
            <Grid item xs={12} container justify="center">
              <DialogTitle id="alert-dialog-title">{this.props.data.title}</DialogTitle>
            </Grid>

            <Grid item xs={12}>
              <DialogContent classes={{ root: this.props.classes.dialogContentRoot }}>{dialogContetnTextComponent}</DialogContent>
            </Grid>

            <Grid item xs={12} container justify="center">
              <DialogActions>
                {this.props.dialogType === 'YesNoDialog' ? (
                  <SecondaryButton onClick={this.props.onNoHandle}>{this.props.data.actionNo}</SecondaryButton>
                ) : undefined}
                <PrimaryButton onClick={this.props.onYesHandle}>{this.props.data.actionYes}</PrimaryButton>
              </DialogActions>
            </Grid>
          </div>
        </Grid>
      </Dialog>
    );
  }
}

export const YesNoDialog = withStyles(baseYesNoDialogStyles)(YesNoDialogComponent);
