import React from 'react';
import { TextField as FormikTextField, TextFieldProps as FormikTTextFieldProps } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { ExtendedTextFieldProps } from './types';

function TextField(props: ExtendedTextFieldProps): React.ReactElement {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.title && <Typography className={classes.title}>{props.title}</Typography>}
      {props.description && <Typography className={classes.description}>{props.description}</Typography>}

      <FormikTextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        className={classes.root}
        fullWidth
        InputLabelProps={{
          classes: { root: classes.inputLabelRoot, outlined: classes.inputLabelOutlined },
        }}
        InputProps={{
          classes: { input: classes.input },
        }}
        variant="outlined"
      />
    </React.Fragment>
  );
}

export default TextField;
