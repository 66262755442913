import { IEnvironmentRegex } from '../types';

/* eslint-disable-next-line unicorn/better-regex */
const phoneNumberRegex = /^(\+36) ?\d{9}$/i;
/* eslint-disable-next-line unicorn/better-regex */
const phoneNumberWithPrefixRegex = /^(\+36) ?\d{9}$/i;
const phoneNumberOnlyWithPrefixRegex = /^(\+36) ?\d{9}$/i;

export const HuEnvironmentRegex: IEnvironmentRegex = {
  phoneNumberRegex,
  phoneNumberWithPrefixRegex,
  phoneNumberOnlyWithPrefixRegex
};
