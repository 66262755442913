import { TFunction } from 'i18next';
import * as yup from 'yup';

import regex from '../../regex';
import { currentEnvironment } from '../../environment/environment';

const phoneNumberTestMethod = (value: string | undefined): boolean => {
  switch (currentEnvironment()) {
    case 'cz': {
      return !!value && (regex.cz.phoneNumberOnlyWithPrefixRegex.test(value) || regex.sk.phoneNumberOnlyWithPrefixRegex.test(value));
    }
    case 'hu':
      return !!value && regex.hu.phoneNumberOnlyWithPrefixRegex.test(value);
    case 'sk':
      return !!value && (regex.cz.phoneNumberOnlyWithPrefixRegex.test(value) || regex.sk.phoneNumberOnlyWithPrefixRegex.test(value));
    default:
      throw new Error('Regex invalid enviroment');
  }
};

const phoneNumberSchema = (t: TFunction): any =>
  yup
    .string()
    .defined(t('contacts:callMeBack:validations:phoneNumberIsRequired'))
    .test('isPhoneNumber', t('contacts:callMeBack:validations:phoneNumberIsNotValid'), (value) => {
      return phoneNumberTestMethod(value);
    });

const callBackDateSchema = (t: TFunction /* , pickupTimes: Date[] */): any =>
  yup
    .mixed<Date>()
    .test('is-date', t('contacts:callMeBack:validations:callBackDateIsRequired'), (date) => {
      if (date === null) {
        return false;
      }

      return true;
    })
    .test('is-date-in-range', t('contacts:callMeBack:validations:callBackDateIsRequired'), (date) => {
      if (!date || date === null) {
        return false;
      }

      date.setHours(0, 0, 0, 0);

      /* const valid = !!pickupTimes.find((item) => {
        return item.getTime() === date.getTime();
      });

      return valid; */

      return true;
    });

export const callMeBackSchema /* : yup.SchemaOf<AnnouncementModel> */ = (t: TFunction): any =>
  yup
    .object({
      phoneNumber: phoneNumberSchema(t),
      date: callBackDateSchema(t),
      time: yup.string().defined(t('contacts:callMeBack:validations:callBackTimeIsRequired')),
    })
    .defined();
