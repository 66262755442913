import { Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import React, { ReactElement, ReactNode, useEffect } from 'react';

import { themeLoaderAsync } from './themeLoader';

const ThemeContext = React.createContext({} as Theme);

export const ThemeContextConsumer = ThemeContext.Consumer;

export const ThemeContextProvider = (props: { children: ReactNode }): ReactElement => {
  const [theme, setTheme] = React.useState<Theme>();

  useEffect(() => {
    themeLoaderAsync()
      .then(function (loadedLocale) {
        setTheme(loadedLocale);
        console.debug('Locale loaded:', loadedLocale);

        return loadedLocale;
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  if (!theme) {
    return <div />;
  }

  console.debug('ThemeContextProvider render');
  return (
    <ThemeContext.Provider value={theme}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
