import { createAction } from '@reduxjs/toolkit';

import { CallCenterDatePublicModel } from '../../api/interfaces';
import { createAsyncAction, createAsyncPaylodAction } from '../../core/actions';
import { CallMeBackModel } from './types';

export const openCallBackFormAction = createAction<void>('xcally/openCallBackForm');
export const sendCallBackFormActionAsync = createAsyncPaylodAction<CallMeBackModel, void, string>('xcally/sendCallBackFormActionAsync');
export const loadCallCenterDateActionAsync = createAsyncPaylodAction<void, CallCenterDatePublicModel[], string>(
  'xcally/loadCallCenterDateActionAsync'
);
