import { AnyAction, getType } from '@reduxjs/toolkit';
import { take, TakeEffect } from 'redux-saga/effects';

import * as actions from './actions';
import { ModalDialogResult, ModalDialogResultWithPayload } from './types';

export function* waitForModalDialogResult(): Generator<TakeEffect, ModalDialogResult, AnyAction> {
  const dialogResult = { selectedActionYes: false } as ModalDialogResult;
  const modalDialogAction = yield take([getType(actions.modalResultSuccess), getType(actions.modalResultCancel)]);

  if (modalDialogAction.type === getType(actions.modalResultSuccess)) {
    dialogResult.selectedActionYes = true;
  }

  return dialogResult;
}

export function* waitForModalDialogResultWithPayload<TPayload>(): Generator<TakeEffect, ModalDialogResultWithPayload<TPayload>, AnyAction> {
  const dialogResult = { selectedActionYes: false } as ModalDialogResultWithPayload<TPayload>;
  const modalDialogAction = yield take([getType(actions.modalResultSuccess), getType(actions.modalResultCancel)]);

  if (modalDialogAction.type === getType(actions.modalResultSuccess)) {
    dialogResult.selectedActionYes = true;
    dialogResult.payload = modalDialogAction.payload;
  }

  return dialogResult;
}
