import { IAutocompelteOption } from '../../components/autocomplete/types';

export const XCallyCallBackFormComponentName = 'xcallyCallBackForm';

export interface CallMeBackModel {
  date: Date | undefined;
  time: string;
  phoneNumber: string;
}

export interface CallMeBackTimeAutocompleteModel extends IAutocompelteOption {
  date: string;
  slotText: string;
}

export interface CallMeBackPickupTimesModel {
  times: CallMeBackTimeAutocompleteModel[];
  date: string;
}

export type CallMeBackDataeAndPickupTimesModel = { [itemId: string]: CallMeBackPickupTimesModel }; /* Record<string, CallMeBackPickupTimesModel>; */
