import i18next from 'i18next';

import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

export default class ErrorHandler implements IErrorHandler {
  handleRequestAsync = async <TResponse extends BaseResponse>(request: () => Promise<TResponse>): Promise<TResponse> => {
    try {
      const responses = await request();
      console.debug(responses);

      return responses;
    } catch (error) {
      console.log(error);

      const response = new BaseResponse() as TResponse;
      response.resultCode = -500;
      response.resultReason = i18next.t('api:invalidOperationError');

      if (error.message && error.message.toLowerCase().includes( i18next.t('api:networkError'))) {
        response.resultReason = i18next.t('api:noInternetError');
      }

      if (error.response) {
        response.httpStatusCode = error.response.status;

        if (error.response.status === 401 && error.response.resultCode === 11003) {
          response.resultReason = i18next.t(`api:resultCodes:${error.response.resultCode}`);
        } else if (error.response.status === 401) {
          response.resultReason = i18next.t('api:unauthorizeError');
        }

        if (error.response.data && error.response.data.resultCode > 0 && error.response.data.resultReason) {
          response.resultCode = error.response.data.resultCode;
          response.resultReason = i18next.t(`api:resultCodes:${error.response.data.resultCode}`);
        }
      }

      return response;
    }
  };
}
