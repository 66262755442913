import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actions from './actions';
import { DialogType, YesNoModalDialogData, BaseModalDialogData, FormModalDialogData } from './types';
import { YesNoDialog } from './yesNoDialog';
import { FormDialog } from './formDialog';
import { IRootState } from '../../store/types';
import { getComponentFor } from './formComponentRegistrator';

interface IModalManagerProps extends IModalManagerStateProps, IModalManagerDispatchProps {}

interface IModalManagerStateProps {
  isVisible: boolean;
  dialogType: DialogType;
  data?: BaseModalDialogData;
}

interface IModalManagerDispatchProps {
  onYesHandle(value?: any): void;
  onNoHandle(): void;
}

class ModalManager extends React.PureComponent<IModalManagerProps> {
  render(): React.ReactNode {
    switch (this.props.dialogType) {
      case 'FormDialog': {
        const component = getComponentFor((this.props.data as FormModalDialogData).contentComponentName);
        
        return (
          <React.Fragment>
            {this.props.data ? (
              <FormDialog
                isVisible={this.props.isVisible}
                data={this.props.data as FormModalDialogData}
                component={component}
                onNoHandle={this.props.onNoHandle}
                dialogType={this.props.dialogType}
              />
            ) : undefined}
          </React.Fragment>
        );
      }
      default: {
        return (
          <React.Fragment>
            {this.props.data ? (
              <YesNoDialog
                isVisible={this.props.isVisible}
                data={this.props.data as YesNoModalDialogData}
                onYesHandle={this.props.onYesHandle}
                onNoHandle={this.props.onNoHandle}
                dialogType={this.props.dialogType}
              />
            ) : undefined}
          </React.Fragment>
        );
      }
    }
  }
}

const mapStateToProps = ({ dialogManager }: IRootState): IModalManagerStateProps => {
  return {
    isVisible: dialogManager.isVisible,
    dialogType: dialogManager.dialogType,
    data: dialogManager.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IModalManagerDispatchProps => {
  return {
    onNoHandle: bindActionCreators(actions.modalResultCancel, dispatch),
    onYesHandle: bindActionCreators(actions.modalResultSuccess, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
