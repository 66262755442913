import { isChrome, isFirefox, isEdge, isOpera, isSafari, browserVersion } from 'react-device-detect';

const chromeMinVersion = 49;
const firefoxMinVersion = 52;
const edgeMinVersion = 79;
const safaryMinVersion = 10;

export const supportedBrowsersService = (): { isBrowserSupported: () => boolean } => {
  return {
    isBrowserSupported: () => {
      try {
        const browserVersionNumber = Number.parseInt(browserVersion, 10);

        return (
          (isChrome && browserVersionNumber >= chromeMinVersion) ||
          (isFirefox && browserVersionNumber >= firefoxMinVersion) ||
          (isEdge && browserVersionNumber >= edgeMinVersion) ||
          (isSafari && browserVersionNumber >= safaryMinVersion) ||
          isOpera
        );
      } catch {
        return false;
      }
    },
  };
};
