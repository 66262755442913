import React, { memo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { bindActionCreators, Dispatch } from 'redux';

import XCallyCallBackForm from './callback';
import { IRootState } from '../../store/types';
import { loadCallCenterDateActionAsync, sendCallBackFormActionAsync } from './actions';
import { hideDialog } from '../../components/dialogManager';
import { CallCenterDatePublicModel } from '../../api/interfaces';
import { CallMeBackDataeAndPickupTimesModel } from './types';
import { DateLocaleContextConsumer } from '../../environment/context';

interface ICallbackContainerDispatchProps {
  sendCallBackFormRequest: typeof sendCallBackFormActionAsync.request;
  loadCallCenterDateRequest: typeof loadCallCenterDateActionAsync.request;
  hideDialog: typeof hideDialog;
}

interface ICallbackContainerProps extends ICallbackContainerStateProps, ICallbackContainerDispatchProps {}

interface ICallbackContainerStateProps {
  errorMessage?: string;
  dates: CallCenterDatePublicModel[];
  initialFocusedDate: Date | undefined;
  pickupDates: Date[];
  pickupTimes: CallMeBackDataeAndPickupTimesModel;
}

const CallbackContainer: React.FunctionComponent<ICallbackContainerProps> = memo(
  (props): React.ReactElement => {
    console.debug('CallbackContainer');

    useEffect(() => {
      props.loadCallCenterDateRequest();
    }, []);

    return (
      <DateLocaleContextConsumer>
        {(locale) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <XCallyCallBackForm
              errorMessage={props.errorMessage}
              initialFocusedDate={props.initialFocusedDate}
              pickupDates={props.pickupDates}
              pickupTimes={props.pickupTimes}
              onSubmitForm={props.sendCallBackFormRequest}
              onCloseForm={props.hideDialog}
            />
          </MuiPickersUtilsProvider>
        )}
      </DateLocaleContextConsumer>
    );
  }
);

const mapStateToProps = ({ xcally }: IRootState): ICallbackContainerStateProps => {
  return {
    errorMessage: xcally.errorMessage,
    dates: xcally.dates,
    initialFocusedDate: xcally.initialFocusedDate,
    pickupDates: xcally.pickupDates,
    pickupTimes: xcally.pickupTimes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ICallbackContainerDispatchProps =>
  bindActionCreators(
    {
      sendCallBackFormRequest: sendCallBackFormActionAsync.request,
      loadCallCenterDateRequest: loadCallCenterDateActionAsync.request,
      hideDialog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CallbackContainer);
