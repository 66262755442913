import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

const devMode = process.env.NODE_ENV === 'development';
const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), routerMiddleware(history), sagaMiddleware];

if (devMode) {
  middleware.push(logger);
}

export default (rootSaga: any, createRootReducer: any, preloadedState = {}): EnhancedStore => {
  const store = configureStore({
    reducer: createRootReducer(history),
    devTools: devMode,
    middleware,
    preloadedState,
  });
  sagaMiddleware.run(rootSaga);

  return store;
};
