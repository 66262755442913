import { createReducer } from '@reduxjs/toolkit';
import produce from 'immer';

import * as actions from './actions';
import { IDialogManagerState } from './types.state';

const initialState: IDialogManagerState = {
  isVisible: false,
  dialogType: 'YesNoDialog',
  data: undefined,
};

const fullPageLoadingIndicatorReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.showDialog<any>(), (state, action) => {
      const nextState = produce(state, (draftState) => {
        draftState.isVisible = true;
        draftState.dialogType = action.payload.dialogType;
        draftState.data = action.payload.data;
      });

      return nextState;
    })
    .addCase(actions.hideDialog, (state, action) => {
      return { ...initialState };
    })
);

export default fullPageLoadingIndicatorReducer;
