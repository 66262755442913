import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseDataResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

export default class PickupTimeClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'pickuptime');
  }

  public async getCurrentPickupTimesAsync(): Promise<BaseDataResponse<Date[]>> {
    return this.getHttpAsync<BaseDataResponse<Date[]>>('', '');
  }
}
