import { EnhancedStore } from '@reduxjs/toolkit';

import { currentEnvironment } from '../environment/environment';
import environmentConfiguration from '../configuration/environment';

export const storeLoaderAsync = (): Promise<EnhancedStore> => {
  let suspender: Promise<EnhancedStore>;

  switch (currentEnvironment()) {
    case 'cz':
      suspender = new Promise((resolve, reject) => {
        import('./cz/store.cz')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Store cs cannot loaded!');
            reject(error);
          });
      });
      break;
    case 'hu':
      suspender = new Promise((resolve, reject) => {
        import('./hu/store.hu')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Store hu cannot loaded!');
            reject(error);
          });
      });
      break;
    case 'sk':
      suspender = new Promise((resolve, reject) => {
        import('./sk/store.sk')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Store sk cannot loaded!');
            reject(error);
          });
      });
      break;
    default:
      throw new Error(`Store for environment ${environmentConfiguration.environment} is not supported!`);
  }

  return suspender;
};
