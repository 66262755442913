import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Box, FormHelperText, Grid, Hidden, Typography } from '@material-ui/core';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';

import { callMeBackSchema } from './validationSchemas';
import TextField from '../../components/textField';
import { nameofFactory } from '../../core/nameOfFactory';
import { DatePickerEx } from '../../components/datePicker';
import { PrimaryButton, SecondaryButton } from '../../components/button';
import { sendCallBackFormActionAsync } from './actions';
import { CallMeBackModel, CallMeBackDataeAndPickupTimesModel, CallMeBackTimeAutocompleteModel, CallMeBackPickupTimesModel } from './types';
import { Autocomplete } from '../../components/autocomplete';

const nameof = nameofFactory<CallMeBackModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inputGridItem: {
      marginTop: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: { marginTop: theme.spacing(1.25) },
    },
    primaryButton: {
      paddingTop: theme.spacing(2),

      [theme.breakpoints.up('sm')]: { paddingLeft: theme.spacing(1), paddingTop: theme.spacing(2.5) },
    },
    secondaryButton: {
      paddingTop: theme.spacing(2.5),

      [theme.breakpoints.up('sm')]: { paddingRight: theme.spacing(1), paddingTop: theme.spacing(2.5) },
    },
  })
);

interface ICallBackFormProps {
  initialFocusedDate: Date | undefined;
  pickupDates: Date[];
  pickupTimes: CallMeBackDataeAndPickupTimesModel;
  errorMessage?: string;
  onSubmitForm: typeof sendCallBackFormActionAsync.request;
  onCloseForm: () => void;
}

const getOptionLabel = (option: CallMeBackTimeAutocompleteModel): string => {
  return option.slotText;
};

const renderOption = (option: CallMeBackTimeAutocompleteModel, state: AutocompleteRenderOptionState): React.ReactElement => {
  return (
    <React.Fragment>
      <Typography>{option.slotText}</Typography>
    </React.Fragment>
  );
};

const FormObserver: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<CallMeBackModel>();
  const [currentDate, setCurrentDate] = useState(values.date);

  useEffect(() => {
    /*  console.log('FormObserver::values', values);
    console.log(currentDate.toDateString());
    console.log(values.date.toDateString()); */

    if (currentDate && values.date && currentDate.toDateString() !== values.date.toDateString()) {
      setFieldValue(nameof('time'), '', true);
    }

    setCurrentDate(values.date);
  }, [values, currentDate]);

  return <React.Fragment />;
};

function CallBackForm(props: ICallBackFormProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const shouldDisableDate = (day: MaterialUiPickersDate): boolean => {
    if (!day) {
      return true;
    }

    const disabled = !props.pickupDates.find((item) => {
      return item.getTime() === day.getTime();
    });

    return disabled;
  };

  return (
    <Formik<CallMeBackModel>
      initialValues={{
        phoneNumber: '',
        date: props.initialFocusedDate,
        time: '',
      }}
      enableReinitialize
      validationSchema={callMeBackSchema(t)}
      validateOnChange
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          props.onSubmitForm(values);
          setSubmitting(false);
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, resetForm }) => {
        return (
          <Form>
            <FormObserver />
            <Box p={1} className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.inputGridItem}>
                  <Field
                    component={TextField}
                    name={nameof('phoneNumber')}
                    label={t('contacts:callMeBack:phoneNumber')}
                    aria-label="call me back phone - number"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputGridItem}>
                  <Field
                    component={DatePickerEx}
                    name={nameof('date')}
                    label={t('contacts:callMeBack:date')}
                    aria-label="call me back - date"
                    shouldDisableDate={shouldDisableDate}
                    disablePast
                    initialFocusedDate={props.initialFocusedDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputGridItem}>
                  <Field
                    label={t('contacts:callMeBack:time')}
                    aria-label="call me back - time"
                    name={nameof('time')}
                    component={Autocomplete}
                    options={values.date && props.pickupTimes[values.date.toUTCString()] ? props.pickupTimes[values.date.toUTCString()].times : []}
                    renderOption={renderOption}
                    getOptionLabel={getOptionLabel}
                  />
                </Grid>
                <Grid item xs={12} container>
                  <Hidden xsDown>
                    <Grid item sm={6} container justify="flex-end" className={classes.secondaryButton}>
                      <SecondaryButton onClick={props.onCloseForm}>{t('common:buttons:close')}</SecondaryButton>
                    </Grid>
                    <Grid item sm={6} container justify="flex-start" className={classes.primaryButton}>
                      <PrimaryButton type="submit">{t('common:buttons:send')}</PrimaryButton>
                    </Grid>
                  </Hidden>

                  <Hidden smUp>
                    <Grid item sm={12} container justify="center" className={classes.secondaryButton}>
                      <SecondaryButton fullWidth onClick={props.onCloseForm}>
                        {t('common:buttons:close')}
                      </SecondaryButton>
                    </Grid>
                    <Grid item sm={12} container justify="center" className={classes.primaryButton}>
                      <PrimaryButton type="submit" fullWidth>
                        {t('common:buttons:send')}
                      </PrimaryButton>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid item xs={12}>
                  {props.errorMessage && (
                    <React.Fragment>
                      <Box pb={3.75} />
                      <Grid container justify="center">
                        <FormHelperText error variant="outlined">
                          {props.errorMessage}
                        </FormHelperText>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

CallBackForm.defaultProps = {
  errorMessage: '',
};

export default CallBackForm;
