import { put, all, takeEvery, ForkEffect, AllEffect, call } from 'redux-saga/effects';

import { openCallBackFormAction, sendCallBackFormActionAsync, loadCallCenterDateActionAsync } from './actions';
import { FormModalDialogData, ModalDialogResult, showDialog, waitForModalDialogResult, YesNoModalDialogData } from '../../components/dialogManager';
import { XCallyCallBackFormComponentName } from './types';
import { ApiClient } from '../../api';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { CallCenterDatePublicModel } from '../../api/interfaces';
import i18n from '../translation';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([
    yield takeEvery(openCallBackFormAction, openCallBackFormActionHandler),
    yield takeEvery(sendCallBackFormActionAsync.request, sendCallBackFormActionAsyncRequestHandler),
    yield takeEvery(sendCallBackFormActionAsync.success, sendCallBackFormActionAsyncSuccessHandler),
    yield takeEvery(loadCallCenterDateActionAsync.request, loadCallCenterDateActionAsyncRequestHandler),
  ]);
}

function* openCallBackFormActionHandler(action: ReturnType<typeof openCallBackFormAction>): any {
  console.debug('openCallBackFormActionHandler');

  yield put(
    showDialog<FormModalDialogData>()({
      data: {
        actionNo: i18n.t('xcally:dialogs:openCallBackForm:actionNo'),
        actionYes: i18n.t('xcally:dialogs:openCallBackForm:actionYes'),
        description: i18n.t('xcally:dialogs:openCallBackForm:description'),
        title: i18n.t('xcally:dialogs:openCallBackForm:title'),
        contentComponentName: XCallyCallBackFormComponentName,
      },
      dialogType: 'FormDialog',
    })
  );

  const modalDialogResult: ModalDialogResult = yield waitForModalDialogResult();
}

function* sendCallBackFormActionAsyncRequestHandler(action: ReturnType<typeof sendCallBackFormActionAsync.request>): any {
  console.debug('sendCallBackFormActionAsync.request');

  try {
    const mutation = { phoneNumber: action.payload.phoneNumber, scheduledAt: new Date(action.payload.time).toISOString() };
    const response: BaseResponse = yield call(
      ApiClient.apiCallHandler,
      {
        context: ApiClient.Xcally,
        apiCallFnName: 'callMeBackAsync',
      },
      mutation
    );

    yield response.resultCode === 0
      ? yield put(sendCallBackFormActionAsync.success())
      : yield put(sendCallBackFormActionAsync.failure(response.resultReason));
    /*  if (response.resultCode === 0) {
      yield put(sendCallBackFormActionAsync.success());
      yield put(hideDialog());
    } else {
      yield put(sendCallBackFormActionAsync.failure(response.resultReason));
    } */
  } catch (error) {
    yield put(sendCallBackFormActionAsync.failure(error));
  }
}

function* sendCallBackFormActionAsyncSuccessHandler(action: ReturnType<typeof sendCallBackFormActionAsync.success>): any {
  console.debug('sendCallBackFormActionAsync.success');

  try {
    yield put(
      showDialog<YesNoModalDialogData>()({
        data: {
          actionNo: i18n.t('xcally:dialogs:sendCallBackForm:actionNo'),
          actionYes: i18n.t('xcally:dialogs:sendCallBackForm:actionYes'),
          description: i18n.t('xcally:dialogs:sendCallBackForm:description'),
          title: i18n.t('xcally:dialogs:sendCallBackForm:title'),
        },
        dialogType: 'YesDialog',
      })
    );
  } catch (error) {
    yield put(sendCallBackFormActionAsync.failure(error));
  }
}

function* loadCallCenterDateActionAsyncRequestHandler(action: ReturnType<typeof loadCallCenterDateActionAsync.request>): any {
  console.debug('sendCallBackFormActionAsync.request');

  try {
    const response: BaseDataResponse<CallCenterDatePublicModel[]> = yield call(ApiClient.apiCallHandler, {
      context: ApiClient.Xcally,
      apiCallFnName: 'getCallCenterDateAsync',
    });

    const actionToPut =
      response.resultCode === 0 ? loadCallCenterDateActionAsync.success(response.data) : loadCallCenterDateActionAsync.failure(response.resultReason);
    yield put(actionToPut);
  } catch (error) {
    yield put(loadCallCenterDateActionAsync.failure(error));
  }
}
