import React from 'react';
import { Helmet } from 'react-helmet';

import { currentEnvironment } from './environment/environment';

const getPageTitle = (): string => {
  const env = currentEnvironment();
  switch (env) {
    case 'cz':
      return 'O2 Financial Services – administrátor služby Pojištění chytrých zařízení od O2';
    case 'hu':
      return 'O2 Financial Services - ügyintézői szolgáltatás, okoseszköz biztosítás a Yetteltől';
    case 'sk':
      return 'O2 Financial Services – administrátor služby O2 Poistka od O2 Slovakia';
    default:
      throw new Error(`Language for ${env} is not supported!`);
  }
};

const getPageDescription = (): string => {
  const env = currentEnvironment();
  switch (env) {
    case 'cz':
      return 'Nahlaste škodní událost snadno a rychle přes naši online samoobsluhu.';
    case 'hu':
      return 'Jelentsen be gyorsan és egyszerűen kárigényt az online önkiszolgáló felületünkön keresztül.';
    case 'sk':
      return 'Nahláste škodovú udalosť na našej zákazníckej linke +421 222 111 555.';
    default:
      throw new Error(`Language for ${env} is not supported!`);
  }
};

const getPageKeywords = (): string => {
  const env = currentEnvironment();
  switch (env) {
    case 'cz':
      return 'O2FS,O2 FS,02FS,Financial Services,O2FS pojistka,FS pojištění zařízení,O2FS škoda,O2FS hlášení,O2FS krádež,O2FS rozbití,O2FS kontakt,O2FS samoobsluha,FS samoobsluha,FS kontakt,O2 FS služby';
    case 'hu':
      return 'O2FS,O2 FS,02FS,Financial Services,O2FS biztosítás,FS készülékbiztosítás,O2FS kár,O2FS jelentés,O2FS eltulajdonítás,O2FS törés,O2FS elérhetőség,O2FS önkiszolgáló felület,FS önkiszolgáló felület,FS elérhetőség,O2 FS szolgáltatások';
    case 'sk':
      return 'O2FS,O2 FS,02FS,Financial Services,O2FS poistka,FS poistenie zariadenia,O2FS škoda';
    default:
      throw new Error(`Language for ${env} is not supported!`);
  }
};

export const HelmetComponentByEnviroment = (): JSX.Element => {
  const title = getPageTitle();
  const description = getPageDescription();
  const keywords = getPageKeywords();

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />    
    </Helmet>
  );
};
