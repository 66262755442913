export default class BaseResponse {
  resultCode: number;

  resultReason: string;

  httpStatusCode: number;

  constructor() {
    this.resultCode = 0;
    this.resultReason = '';
    this.httpStatusCode = 0;
  }
}
