import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseDataResponse, BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';
import { CallCenterDatePublicModel, CallMeBackCreateMutation } from '../interfaces';

export default class XcallyClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'xcally');
  }

  public async callMeBackAsync(mutation: CallMeBackCreateMutation): Promise<BaseResponse> {
    return this.postHttpAsync<BaseResponse, CallMeBackCreateMutation>('call-me-back', mutation);
  }

  public async getCallCenterDateAsync(): Promise<BaseDataResponse<CallCenterDatePublicModel[]>> {
    return this.getHttpAsync<BaseDataResponse<CallCenterDatePublicModel[]>>('valid-call-times', '');
  }
}
