import urlConfiguration from '../../configuration/url';

const ROUTES = {
  root: '/',
  home: '/home',
  cookies: '/cookies',
  support: '/support',
  supportDelkaOpravy: '#delkaOpravy',
  supportPrepravaDoServisu: '#prepravaDoServisu',
  supportStavSkodniUdalosti: '#stavSkodniUdalosti',
  supportPosouzeniNaroku: '#posouzezniNaroku',
  supportVyseSpoluučasti: '#vyseSpoluucasti',
  authenticatedSection: '/auth',
  authenticatedSectionAnnouncement: '/auth/announcement',
  authenticatedSectionAnnouncementDemage: '/auth/announcement?event=damage',
  authenticatedSectionAnnouncementTheft: '/auth/announcement?event=theft',
  authenticatedSectionAnnouncementLoss: '/auth/announcement?event=loss',
  authenticatedSectionAnnouncementExtendedWarranty: '/auth/announcement?event=extendedWarranty',
  authenticatedSectionSelfcare: '/auth/selfcare',
  authenticatedSectionSelfcareContract: '/auth/selfcare/contract',
  authenticatedSectionSelfcareClaim: '/auth/selfcare/claim',
  authenticatedSectionSelfcareNewMessage: '/auth/selfcare/message',
  authenticatedSectionSelfcareMessageSent: '/auth/selfcare/sent',
  announcement: '/announcement',
  signOut: '/signout',
  unsupportedBrowser: '/unsupported-browser',
  supportedBrowser: '/supported-browser',
  whistleblowing: '/whistleblowing',
  whistleblowingSent: '/whistleblowingsent',
  'external/cookies/chrome': 'https://support.google.com/chrome/answer/95647?hl=en',
  'external/cookies/firefox':
    'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&&redirectlocale=en-US',
  'external/cookies/internet-explorer': 'https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d',
  'external/cookies/safari': 'https://help.apple.com/safari/mac/8.0/',
  // HU
  'external/hu/maxima': 'https://maximabiztosito.hu/sites/default/files/Adatvedelem-Adatkezelesi_tajeoztato_Yettel_2022_03.pdf',
  'external/yettel/informace-o-zpracovani-osobnich-udaju':
    'https://maximabiztosito.hu/sites/default/files/2022-02/Adatvedelem-Adatkezelesi_tejekoztato_Yettel_2022_03.pdf',
  'external/yettel/informacni-dokument': 'https://maximabiztosito.hu/sites/default/files/2022-02/Yettel_IPID_Keszulekbiztositas_Tajekoztato.pdf',
  'external/yettel/vseobecne-pojistne-podminky': 'https://maximabiztosito.hu/biztositas',
  'external/telenor/postup-reseni-stiznosti': 'https://maximabiztosito.hu/sites/default/files/2021-11/Panaszkezelesi_tajekoztato_2021THU.pdf',
  'external/yettel': 'https://www.yettel.hu',
  'external/yettel/keszulekbiztositas': 'https://www.yettel.hu/keszulekbiztositas',
  'external/yettel/keszulekbiztositas#uj-biztositas': 'https://www.yettel.hu/keszulekbiztositas#uj-biztositas',
  'external/yettel/informacni-dokument-maxima':
    'https://maximabiztosito.hu/sites/default/files/2022-02/Yettel_K%C3%A9szulekbiztositas_ASZF_20220301_final.pdf',
  'external/hu/icloud': 'https://support.apple.com/hu-hu/guide/icloud/mmfc0eeddd/icloud',
  'external/hu/tmx/biztositasi-szervizgarancia': 'https://tinyurl.com/TMXgarancia',
  // CZ  
  'external/cz/maxima': 'https://www.o2.cz/osobni/-a15962?field=data&_linka=a65117',
  'external/o2/ramcova-pojistna-smlouva-do-7.10.2022': 'https://www.o2.cz/osobni/-a76692?field=data',
  'external/o2/ramcova-pojistna-smlouva-od-7.10.2022': 'https://www.o2.cz/-a202801?field=data ',
  'external/o2/ramcova-pojistna-smlouva-od-1.2.2024': 'https://www.o2.cz/-a134579?field=data',
  'external/o2/informacni-dokument-do-7.10.2022': 'https://www.o2.cz/osobni/-a76804?field=data',
  'external/o2/informacni-dokument-od-7.10.2022': 'https://www.o2.cz/-a202803?field=data',
  'external/o2/informacni-dokument-od-1.2.2024': 'https://www.o2.cz/-a134581?field=data',
  'external/o2/informace-o-zpracovani-osobnich-udaju': 'https://www.o2.cz/osobni/-a15962?field=data&_linka=a65117',
  'external/o2family/ramcova-pojistna-smlouva-do-7.10.2022': 'https://www.o2family.cz/files/content/hwpoj/rps-o2family-20200812.pdf',
  'external/o2family/ramcova-pojistna-smlouva-od-7.10.2022': 'https://www.o2family.cz/files/content/hwpoj/2022/rps-o2f-hw-2022.pdf',
  'external/o2family/ramcova-pojistna-smlouva-od-1.2.2024': 'https://www.o2.cz/-a134579?field=data',
  'external/o2family/informacni-dokument-do-7.10.2022':
    'https://www.o2family.cz/files/content/hwpoj/informacni-dokument-k-pojistnemu-produktu-1.9.2020.pdf',
  'external/o2family/informacni-dokument-od-7.10.2022':
    'https://www.o2family.cz/files/content/hwpoj/2022/informacni-dokument-k-pojistnemu-produktu-o2f-2022.pdf',
  'external/o2family/informacni-dokument-od-1.2.2024': 'https://www.o2.cz/-a134581?field=data',
  'external/o2family/informace-o-zpracovani-osobnich-udaju':
    'https://www.o2family.cz/files/content/pojisteni/memorandum-o-ochrane-osobnich-udaju-o2-hw-pojisteni.pdf',
  'external/o2/products': 'https://www.o2.cz/osobni/pojistka-mobil',
  'external/o2/products/cestovni-pojisteni': 'https://www.o2.cz/osobni/cestovni-pojisteni',
  'external/o2/products/zarizeni-na-splatky': 'https://www.o2.cz/osobni/zarizeni-na-splatky',
  'external/o2/products/platba-pres-o2': 'https://www.o2.cz/osobni/platba-pres-o2',
  'external/o2/products/o2-rb-karta': 'https://www.o2.cz/osobni/o2-rb-karta',
  'external/cz/icloud': 'https://support.apple.com/cs-cz/guide/icloud/mmfc0eeddd/icloud',
  'external/cz/dpd': 'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/jak-spravne-zabalit-zasilky/',
  'external/cz/financni-sluby/pojisteni-mobilu': 'https://www.o2.cz/financni-sluzby/pojisteni-mobil',
  'external/cz/o2/ramcova-pojistna-smlouva': 'http://www.o2.cz/osobni/-a51044?field=data',
  'external/cz/o2/projedny': 'https://www.o2.cz/prodejny ',
  'external/cz/o2': 'https://www.o2.cz/',
  // SK
  'external/sk': 'https://www.o2.sk',
  'external/sk/o2pojistka': 'https://www.o2.sk/ponuka/telefony-a-zariadenia/o2poistka',
  'external/sk/maxima-informace-o-zpracovani-osobnich-udaju': 'https://www.pojistovnamaxima.sk/sk/informacie-o-spracuvani-osobnych-udajov',
  'external/sk/prodejny': 'https://www.o2.sk/podpora/centrum-podpory/kontakty/o2-predajne',
  'external/sk/o2/projedny': 'https://www.o2.sk/podpora/centrum-podpory/kontakty/o2-predajne',
  'external/sk/mojeO2': 'https://www.o2.sk/moje-o2',
  'external/sk/icloud': 'https://support.apple.com/sk-sk/guide/icloud/mmfc0eeddd/icloud',
  // EU
  'external/eu/all': urlConfiguration.euWebUrl,
};

export default ROUTES;

export const HASHS = {
  generalContact: 'generalContact',
};

export type AuthenticationChatType = 'none' | 'contract' | 'announcement';
