import { createReducer } from '@reduxjs/toolkit';
import produce from 'immer';

import { Stack } from '../../core/stack';
import { IFullPageLoadingIndicatorState } from './types.state';
import * as actions from './actions';

export const initialState: IFullPageLoadingIndicatorState = {
  showIndicator: false,
  /* indicatorStack: new Stack<boolean>(), */
  indicatorStack: 0,
};

const fullPageLoadingIndicatorReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.showFullPageLoadingIndicator, (state, action) => {
      const nextState = produce(state, (draftState) => {
        draftState.indicatorStack += 1;
        draftState.showIndicator = draftState.indicatorStack !== 0;

        /* draftState.indicatorStack.push(true);
        draftState.showIndicator = !draftState.indicatorStack.isEmpty(); */
      });

      return nextState;
    })
    .addCase(actions.hideFullPageLoadingIndicator, (state, action) => {
      const nextState = produce(state, (draftState) => {
        draftState.indicatorStack -= 1;
        draftState.showIndicator = draftState.indicatorStack !== 0;

        /* draftState.indicatorStack.pop();
        draftState.showIndicator = !draftState.indicatorStack.isEmpty(); */
      });

      return nextState;
    })
);

export default fullPageLoadingIndicatorReducer;
