import { put, all, takeEvery, ForkEffect, AllEffect, PutEffect, call, CallEffect } from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';
import * as navigationActions from './actions';
import ROUTES, { HASHS } from './routes';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([
    yield takeEvery(navigationActions.navigateToAction, navigateToHandler),
    yield takeEvery(navigationActions.navigateToExternalAction, navigateToExternalHandler),
    yield takeEvery(navigationActions.navigateToHashAction, navigateToHashHandler),
    yield takeEvery(navigationActions.navigateBackAction, navigateBackHandler),
  ]);
}

function navigateToExternalPage(url: string): void {
  window.location.assign(url);
}

function* navigateToHandler(action: ReturnType<typeof navigationActions.navigateToAction>): Generator<PutEffect, void, void> {
  try {
    const routeUrl = ROUTES[action.payload];
    yield put(push(routeUrl));
  } catch (error) {
    console.error(error);
  }
}

function* navigateToExternalHandler(action: ReturnType<typeof navigationActions.navigateToExternalAction>): Generator<CallEffect, void, void> {
  try {
    const externalUrl = ROUTES[action.payload];
    yield call(navigateToExternalPage, externalUrl);
  } catch (error) {
    console.error(error);
  }
}

function* navigateToHashHandler(action: ReturnType<typeof navigationActions.navigateToHashAction>): Generator<PutEffect, void, void> {
  try {
    const routeUrl = ROUTES[action.payload.route];
    const hash = HASHS[action.payload.hash];
    yield put(
      push({
        pathname: routeUrl,
        hash: `#${hash}`,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

function* navigateBackHandler(action: ReturnType<typeof navigationActions.navigateBackAction>): Generator<PutEffect, void, void> {
  try {
    yield put(goBack());
  } catch (error) {
    console.error(error);
  }
}
