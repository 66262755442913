import React, { lazy, Suspense, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeContextProvider } from '../theme';
import FullPageLoadingIndicatorContainer from '../../components/fullPageLoadingIndicator/fullPageLoadingIndicatorContainer';
import { supportedBrowsersService } from './supportedBrowserService';
import CookiePanel from '../cookiePanel';
import { DateLocaleContextProvider } from '../../environment/context';

const App = lazy(() => import('../app/app'));
const UnsupportedApp = lazy(() => import('../unsupportedBrowserApp/unsupportedBrowserApp'));
const ModalDialogContainer = lazy(() => import('../../components/dialogManager/modalDialogContainer'));
const renderLoader = (): React.ReactElement => <FullPageLoadingIndicatorContainer size={90} />;

function BrowserBaseApp(): React.ReactElement {
  const [isBrowserSupported, setIsBrowserSupported] = React.useState<boolean>(false);
  
  useEffect(() => {
    const isBrowserSupportedResult = supportedBrowsersService().isBrowserSupported();
    setIsBrowserSupported(isBrowserSupportedResult);
  }, []);

  const childrenComponent = isBrowserSupported ? <App /> : <UnsupportedApp />;
  console.log('BrowserBaseApp render');

  return (
    <Suspense fallback={renderLoader()}>
      <ThemeContextProvider>
        <DateLocaleContextProvider>
          <CssBaseline />
          <ModalDialogContainer />
          <FullPageLoadingIndicatorContainer size={90} />
          {childrenComponent}
          <CookiePanel />
        </DateLocaleContextProvider>
      </ThemeContextProvider>
    </Suspense>
  );
}

export default BrowserBaseApp;
