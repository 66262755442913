import { put, ForkEffect, all, takeEvery, AllEffect, PutEffect } from 'redux-saga/effects';

import { hideDialog, modalResultCancel, modalResultSuccess } from './actions';

function* handleDialogResult(): Generator<PutEffect, void, unknown> {
  yield put(hideDialog());
}

export default function* root(): Generator<ForkEffect<never> | AllEffect<unknown>, void, unknown> {
  yield all([yield takeEvery([modalResultSuccess, modalResultCancel], handleDialogResult)]);
}
