import React, { useCallback } from 'react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { TextField as MuiTextField } from '@material-ui/core';
import MuiAutocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';

import { useStylesTextField } from '../textField';
import { IAutocompelteOption } from './types';

const Autocomplete = <T extends IAutocompelteOption>(props: {
  id: string;
  label: string;
  options: T[];
  key: keyof T;
  getOptionLabel: (option: T) => string;
  renderOption: (option: T) => React.ReactElement;
  getOptionSelected: (option: T, value: T) => boolean;
  field: FieldInputProps<string>;
}): React.ReactElement => {
  const textFieldClasses = useStylesTextField();
  const [field, meta, helpers] = useField<string>(props.field.name);

  /* eslint-disable-next-line unicorn/no-null */
  const [value, setValue] = React.useState<T | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  if (field.value === '' && value) {
    /* eslint-disable-next-line unicorn/no-null */
    setValue(null);
  }

  if (field.value === '' && inputValue) {
    setInputValue('');
  }

  const handleOnChange = useCallback((event: any, newValue: T | null) => {
    console.log('Selected value:', newValue);
    setValue(newValue);

    if (newValue) {
      helpers.setValue(newValue.id);
    } else {
      helpers.setValue('');
    }
  }, []);

  return (
    <MuiAutocomplete<T>
      id={props.id}
      onChange={handleOnChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={props.options}
      classes={{
        inputRoot: textFieldClasses.autocompleteInputRoot,
        input: textFieldClasses.autocompleteInput,
      }}
      fullWidth
      autoHighlight
      clearText="Odebrat"
      openText="Otevřít"
      getOptionLabel={props.getOptionLabel}
      renderOption={props.renderOption}
      getOptionSelected={props.getOptionSelected}
      value={value}
      inputValue={inputValue}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          label={props.label}
          fullWidth
          error={!!meta.error}
          helperText={meta.error}
          variant="outlined"
          InputLabelProps={{
            classes: { root: textFieldClasses.inputLabelRoot, outlined: textFieldClasses.inputLabelOutlined },
          }}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
