import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DateTimePicker, DateTimePickerProps } from 'formik-material-ui-pickers';
import { IconButton, InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inputLabelRoot: {
      fontSize: '16px',
      '&$inputLabelOutlined': {
        transform: 'translate(16px, 17.5px) scale(1)',
        [theme.breakpoints.up('sm')]: {
          transform: 'translate(16px, 15px) scale(1)',
        },
      },
      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(16px, -6.5px) scale(0.75)',
      },
    },
    inputLabelOutlined: {},
    input: {
      backgroundColor: theme.palette.background.paper,
      height: '16px',
      lineHeight: 1,
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2.5),
      paddingTop: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(1.875),
        paddingTop: theme.spacing(1.875),
      },
    },
  })
);

function DateTimePickerEx(props: DateTimePickerProps): React.ReactElement {
  const classes = useStyles();

  return (
    <DateTimePicker
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      className={classes.root}
      fullWidth
      InputLabelProps={{
        classes: { root: classes.inputLabelRoot, outlined: classes.inputLabelOutlined },
      }}
      InputProps={{
        classes: { input: classes.input },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CalendarToday />
            </IconButton>
          </InputAdornment>
        ),
      }}
      DialogProps={{ style: { zIndex: 300000 } }}
      inputVariant="outlined"
      format="d.M. yyyy HH:mm"
      cancelLabel="Zrušit"
      ampm={false}
    />
  );
}

export default DateTimePickerEx;
