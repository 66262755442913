import { AxiosInstance } from 'axios';

import BaseClient from './baseClient';
import { BaseDataResponse } from '../responses';
import { IErrorHandler } from '../abstractions';
import { ContractModel } from '../interfaces';

export default class ClientClient extends BaseClient {
  constructor(errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(errorHandler, axiosInstance, 'client');
  }

  public async getContractsAsync(): Promise<BaseDataResponse<ContractModel[]>> {
    return this.getHttpAsync<BaseDataResponse<ContractModel[]>>('contract', '');
  }
}
