import React, { useEffect } from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import { ROUTES } from '../navigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    link: {
      color: theme.palette.common.white,
      textDecoration: 'underline',
      '&:hover': { textDecoration: 'none' },
    },
  })
);

const styles = {
  banner: {
    background: '#334051',
    opacity: 0.88,
    zIndex: '300000',
  },
  button: {
    border: '1px solid white',
    borderRadius: 4,
    height: 32,
    lineHeight: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    opacity: 1,
    padding: '0px 15px',
    margin: '18px',
  },
};

function CookiePanelComponent(): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={t('cookies:cookiePanel:button')}
        cookieName="of2s-cookie-consent"
        style={styles.banner}
        buttonStyle={styles.button}
        expires={150}
      >
        <Typography variant="body1">
          {t('cookies:cookiePanel:title')}
          <Link to={ROUTES.cookies} className={classes.link}>
            {t('cookies:cookiePanel:titleLink')}
          </Link>{' '}
          {t('cookies:cookiePanel:consent')}
        </Typography>
      </CookieConsent>
    </div>
  );
}

export default CookiePanelComponent;
