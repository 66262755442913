import environmentConfiguration from '../configuration/environment';
import { Environment, LanguageCode } from '../core/types';

export const currentEnvironment = (): Environment => {
  switch (environmentConfiguration.environment) {
    case 'cz':
      return 'cz';
    case 'hu':
      return 'hu';
    case 'sk':
      return 'sk';
    default:
      throw new Error(`Environment for ${environmentConfiguration.environment} is not supported!`);
  }
};

export const currentLanguageCode = (): LanguageCode => {
  switch (environmentConfiguration.environment) {
    case 'cz':
      return 'cs';
    case 'hu':
      return 'hu';
    case 'sk':
      return 'sk';
    default:
      throw new Error(`Language for ${environmentConfiguration.environment} is not supported!`);
  }
};

export const dateLocaleLoaderAsync = (): Promise<Locale> => {
  let suspender: Promise<Locale>;

  switch (currentEnvironment()) {
    case 'cz':
      suspender = new Promise((resolve, reject) => {
        import('date-fns/locale/cs')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Locale cs cannot loaded!');
            reject(error);
          });
      });
      break;
    case 'hu':
      suspender = new Promise((resolve, reject) => {
        import('date-fns/locale/hu')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Locale hu cannot loaded!');
            reject(error);
          });
      });
      break;
    case 'sk':
      suspender = new Promise((resolve, reject) => {
        import('date-fns/locale/sk')
          .then((module) => {
            resolve(module.default);

            return module.default;
          })
          .catch((error) => {
            console.error('Locale sk cannot loaded!');
            reject(error);
          });
      });
      break;
    default:
      throw new Error(`Locale for environment ${environmentConfiguration.environment} is not supported!`);
  }

  return suspender;
};
