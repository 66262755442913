import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';

import { DialogManagerPayload } from './types';

export const showDialog = <TData>(): ActionCreatorWithPayload<DialogManagerPayload<TData>> =>
  createAction<DialogManagerPayload<TData>>('dialogManager/SHOW_MODAL_DIALOG');
  
export const hideDialog = createAction('dialogManager/HIDE_MODAL_DIALOG');
export const modalResultSuccess = createAction('dialogManager/DIALOG_OK');
export const modalResultCancel = createAction('dialogManager/DIALOG_NO');
