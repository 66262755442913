import { currentEnvironment } from '../environment/environment';

import { CzEnvironmentRegex } from './cz/regex.cz';
import { HuEnvironmentRegex } from './hu/regex.hu';
import { SkEnvironmentRegex } from './sk/regex.sk';
import { IRegex } from './types';

const emailRegex = /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i;
const phoneNumberWithPlusPrefixRegex = /^(\+\d{2,3})\d{8,12}$/i;

let phoneNumberRegex = /$/i;
let phoneNumberWithPrefixRegex = /$/i;

switch (currentEnvironment()) {
  case 'cz':
    phoneNumberRegex = CzEnvironmentRegex.phoneNumberRegex;
    phoneNumberWithPrefixRegex = CzEnvironmentRegex.phoneNumberWithPrefixRegex;
    break;
  case 'hu':
    phoneNumberRegex = HuEnvironmentRegex.phoneNumberRegex;
    phoneNumberWithPrefixRegex = HuEnvironmentRegex.phoneNumberWithPrefixRegex;
    break;
  case 'sk':
    phoneNumberRegex = SkEnvironmentRegex.phoneNumberRegex;
    phoneNumberWithPrefixRegex = SkEnvironmentRegex.phoneNumberWithPrefixRegex;
    break;
  default:
    throw new Error('Regex invalid enviroment');
}

const regex: IRegex = {
  isEmail: (contact: string): boolean => emailRegex.test(contact),
  isPhoneNumber: (contact: string): boolean => phoneNumberRegex.test(contact),
  isPhoneNumberWithPrefix: (contact: string): boolean => phoneNumberWithPrefixRegex.test(contact),
  isPhoneNumberWithPlusPrefix: (contact: string): boolean => phoneNumberWithPlusPrefixRegex.test(contact),

  cz: CzEnvironmentRegex,
  sk: SkEnvironmentRegex,
  hu: HuEnvironmentRegex,
};

export default regex;
