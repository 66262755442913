import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';

import { LinkButton } from '../../features/navigation';
import { hideDialog } from './actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    link: {
      /* color: theme.palette.text.secondary, */
      textDecoration: 'underline',
      '&:hover': { textDecoration: 'none' },
    },
    text: {},
  })
);

function UnsupportedBrowserDialogContentText(): React.ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLinkClick = useCallback(() => {
    dispatch(hideDialog());
  }, [dispatch]);

  return (
    <DialogContentText id="alert-dialog-description">
      {t('unsupportedBrowser:dialog:text')} &nbsp;
      <LinkButton
        route="supportedBrowser"
        classes={{ root: classes.link, text: classes.text }}
        textAlign="center"
        text={t('unsupportedBrowser:dialog:supportedBrowserLinkText')}
        textVariant="body1"
        onClick={onLinkClick}
      />
    </DialogContentText>
  );
}

export default UnsupportedBrowserDialogContentText;
