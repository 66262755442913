import { stringToDate } from '../utils/stringToDate';

export class StringToDateInterceptor {
  static execute(response: any): any {
    const { data } = response.data;

    stringToDate(data);

    return response;
  }
}
