import React, { ReactElement, useEffect } from 'react';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';

import { storeLoaderAsync } from './storeLoader';

export const StoreLoader = (props: { children: ReactElement }): ReactElement => {
  const [store, setStore] = React.useState<Store<any, AnyAction>>();

  useEffect(() => {
    storeLoaderAsync()
      .then(function (loadedStore) {
        setStore(loadedStore);
        console.debug('Store loaded:', loadedStore);

        return loadedStore;
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  if (!store) {
    return <div />;
  }

  console.debug('StoreLoader render');

  return <Provider store={store}>{props.children}</Provider>;
};
