import { BaseResponse } from '../responses';
import { IBaseModel } from '../interfaces';

export const apiCallHandler = async <
  TModel extends IBaseModel,
  TResult extends BaseResponse,
  Ctx extends { [P in Name]: ApiCallFn },
  Name extends string,
  ApiCallFn extends (this: Ctx, ...args: any[]) => Promise<TResult>
>(
  ctxAndFnName: { context: Ctx; apiCallFnName: Name },
  ...args: ApiCallParameters<ApiCallFn>
): Promise<TResult> => {
  console.debug(...args);

  const { apiCallFnName, context } = ctxAndFnName;
  const response = await context[apiCallFnName](...args);

  console.debug(response);

  try {
    return response as TResult;
  } catch (error) {
    console.warn(error);
    return response as TResult;
  }
};

type ApiCallParameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? P : never;
