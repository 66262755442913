const isIso8601 = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false;
  }

  const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\+\d{2}:\d{2})/;
  return ISO_8601.test(value);
};

export const stringToDate = (body: any): void => {
  if (body === null || body === undefined) {
    return;
  }

  if (typeof body !== 'object') {
    if(isIso8601(body)) {
      // eslint-disable-next-line no-param-reassign
      body = new Date(Date.parse(body));
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIso8601(value)) {
      // eslint-disable-next-line no-param-reassign
      body[key] = new Date(Date.parse(value));
    } else if (typeof value === 'object') {
      stringToDate(value);
    }
  }
};