import * as React from 'react';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { DialogType, FormModalDialogData } from './types';
import { BaseFormDialogClassKey, baseFormDialogStyles } from './baseDialog';

interface IFormDialogProps {
  isVisible: boolean;
  data: FormModalDialogData;
  classes: Partial<ClassNameMap<BaseFormDialogClassKey>>;
  dialogType: DialogType;
  component: React.ComponentType;
  onNoHandle(): void;
}

class FormDialogComponent extends React.PureComponent<IFormDialogProps> {
  render(): React.ReactNode {
    return (
      <Dialog
        open={this.props.isVisible}
        onClose={this.props.onNoHandle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: this.props.classes.paper }}
      >
        <div className={this.props.classes.dialogRoot}>
          <Grid container justify="center">
            <Grid item xs={12} container justify="center">
              <DialogTitle id="alert-dialog-title" className={this.props.classes.dialogTitle}>
                {this.props.data.title}
              </DialogTitle>
            </Grid>
            <Grid item xs={12} container justify="center">
              <DialogContentText id="alert-dialog-description" className={this.props.classes.dialogDesciption}>
                {this.props.data.description}
              </DialogContentText>
            </Grid>

            <Grid item xs={12}>
              <this.props.component />
            </Grid>
          </Grid>
        </div>       
      </Dialog>
    );
  }
}

export const FormDialog = withStyles(baseFormDialogStyles)(FormDialogComponent);
